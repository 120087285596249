import React from "react";
import { useSidebars } from "../../store/hooks/useSidebars";
import { useUserContext } from "../../../../store/hooks/useUserContext";
import { AssignmentStatusEnum, EticketSourceTypeEnum, IAssignmentConfiguration, TAssignment } from "../../types/assignments";
import { paginationContext, PaginationEnum } from "../../store/pager";
import { getAssignmentConfigurations, resolveAssignment } from "../../services/assignments";
import { intesa_green } from "../../../../themes/themes";
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar } from "@mui/material";
import { getSuperMetricConfiguration } from "../../services/supermetric";
import { ISuperMetricConfiguration } from "../../types/etickets";
import { DetailsContainer } from "../DetailsContainer/DetailsContainer";
type Props = {
    assignment: TAssignment
    fullWidth?: boolean
}
export const AssignmentDetailToolbar: React.FC<Props> = ({ assignment,fullWidth }) => {
    const { isMobile } = useSidebars();
    const { account } = useUserContext();
    const notResolvable = React.useMemo(() => account.username.toLowerCase() !== assignment.OwnerEmail.toLowerCase() || assignment.Status === AssignmentStatusEnum.Resolved, [assignment, account])
    // const notResolvable = React.useMemo(() => assignment.Status === AssignmentStatusEnum.Resolved, [assignment, account])
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [assignmentConfiguration, setAssignmentConfiguration] = React.useState<IAssignmentConfiguration|ISuperMetricConfiguration>()
    React.useEffect(() => {
        if (assignment.SourceType === EticketSourceTypeEnum.Metric) {
            getAssignmentConfigurations(assignment.ExternalReferenceId)
                .then((cfgs) => {
                    setAssignmentConfiguration(cfgs.find((cfg) => cfg.OwnerMail === assignment.OwnerEmail))
                })
                .catch((err) => console.log(err))
        }
        if(assignment.SourceType === EticketSourceTypeEnum.SuperMetric){
            getSuperMetricConfiguration(assignment.ExternalReferenceId)
            .then((cfg) => setAssignmentConfiguration(cfg))
            .catch((err) => console.log(err))
        }
    }, [assignment.OwnerEmail, assignment.ExternalReferenceId])
    const [reason, setReason] = React.useState<string | null | undefined>(assignmentConfiguration?.ClosureReason ? assignmentConfiguration.ClosureReason[0] : null)
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setReason(e.target.value)
    }

    const handleClick = () => {
        if (reason) {
            setIsLoading(true);
            resolveAssignment(assignment.Id, reason)
                .then((res) => {
                    console.log(res)
                    console.log(paginationState.pagination.ASSIGNMENTS.items.map((a) => a.Id === assignment.Id ? res : a))
                    paginationDispatch({
                        type: "SET_PAGINATION", payload: {
                            key: PaginationEnum.ASSIGNMENTS,
                            pagination: {
                                ...paginationState.pagination.ASSIGNMENTS,
                                items: paginationState.pagination.ASSIGNMENTS.items.map((a) => a.Id === assignment.Id ? res : a)
                            }
                        }
                    });
                    setOpenSnackBar(true)
                })
                .finally(() => setIsLoading(false))

        }
    }
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const label = "Motivazione Chiusura"
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, width: 100, height: 10, marginTop: 20 }} />
    }
    if (!assignmentConfiguration?.ClosureReason) {
        return <p className='text-lg text-center'>Nessuna closure reason configurata.</p>
    }
    // return <div
    //     style={{ width: isMobile ? "100%" : "calc(100% - 1rem)"}}
    //     className='border rounded p-2 border-intesa-green bg-gray-100 my-2'>
            return <DetailsContainer fullWidth={fullWidth}>
        {assignmentConfiguration && <div style={{flexDirection:isMobile ? 'column' : 'row',display:'flex' }}>
            <p className='text-2xl'>
                {assignmentConfiguration.IsAutomaticClose ?
                    `Se ritieni che questa segnalazione sia già stata gestita o non sia rilevante/corretta ti chiediamo di risolvere il caso ${assignmentConfiguration.ClosureReason.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                    :
                    `Questa assegnazione si chiuderà in automatico quando il problema che l'ha generata sarà risolto. Puoi lasciare un feedback ${assignmentConfiguration.ClosureReason.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                }
            </p>
            <div className='flex items-center justify-end' style={{ marginTop: 10, }}>
                <FormControl variant="outlined" style={{ minWidth: 200, height: 60 }}>
                    <InputLabel htmlFor={"supermetricdetail-toolbar-select"}>{label}</InputLabel>
                    {assignmentConfiguration.ClosureReason && assignmentConfiguration.ClosureReason.length === 0 ?
                        <OutlinedInput
                            disabled={isLoading || notResolvable}
                            value={reason} style={{ height: 60,backgroundColor:'white',border:'1px solid rgb(200,200,200)',borderRadius:0 }} id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />
                        :
                        <Select
                            disabled={isLoading || notResolvable}
                            id="supermetricdetail-toolbar-select"
                            label={label}
                            input={<OutlinedInput style={{ height: 60,backgroundColor:'white',border:'1px solid rgb(200,200,200)',borderRadius:0 }} id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />}

                        >
                            {assignmentConfiguration.ClosureReason.map((reason) => <MenuItem value={reason}>{reason}</MenuItem>)}
                        </Select>
                    }
                </FormControl>
                <div className='ml-4' />

                <Button
                    style={{ height: 60, width: 120, borderRadius:0 }}
                    variant='contained'
                    disabled={!reason || isLoading || notResolvable}
                    onClick={handleClick}
                >
                    Invia
                </Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackBar(false)}
                    message={`Segnalazione risolta correttamente`}
                />
            </div>
        </div>}
        </DetailsContainer>
    {/* </div> */}

}